import { createAction, props } from '@ngrx/store';

import { Ticket } from './tickets.models';

export const initTickets = createAction('[Tickets Page] Init', props<{ pageIndex: number }>());

export const changePage = createAction('[Tickets Page] Ticket Page changed', props<{ page: number }>());

export const loadTicketsSuccess = createAction('[Tickets/API] Load Tickets Success', props<{ tickets: Ticket[] }>());
export const loadTicketsFailure = createAction('[Tickets/API] Load Tickets Failure', props<{ error: any }>());
