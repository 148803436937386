import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DataAccessFairsFacade } from '@planone/bau365/data-access-fair-data';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import * as TicketsActions from './tickets.actions';

import { TicketService } from '../../service/ticket.service';

@Injectable()
export class TicketsEffects {
  private actions$ = inject(Actions);
  private service = inject(TicketService);
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private fairsFacade = inject(DataAccessFairsFacade);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketsActions.initTickets),
      withLatestFrom(this.fairsFacade.fairsConfig$),
      switchMap(([_action, fairsConfig]) =>
        this.service.loadTickets(fairsConfig.activeFair).pipe(
          map((tickets) => TicketsActions.loadTicketsSuccess({ tickets })),
          catchError((error) => of(TicketsActions.loadTicketsFailure({ error })))
        )
      )
    )
  );

  changePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TicketsActions.changePage),
        tap(({ page }) =>
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: page > 1 ? page : null },
            queryParamsHandling: 'merge',
          })
        )
      ),
    { dispatch: false }
  );
}
