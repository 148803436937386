import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Ticket } from './tickets.models';
import { TICKETS_FEATURE_KEY, ticketsAdapter, TicketsState } from './tickets.reducer';

// Lookup the 'Tickets' feature state managed by NgRx
export const selectTicketsState = createFeatureSelector<TicketsState>(TICKETS_FEATURE_KEY);

const { selectAll, selectEntities } = ticketsAdapter.getSelectors();

export const isLoaded = createSelector(selectTicketsState, (state: TicketsState) => state.loaded);
export const getError = createSelector(selectTicketsState, (state: TicketsState) => state.error);
export const getAllTickets = createSelector(selectTicketsState, (state: TicketsState) => selectAll(state));
export const getAllTicketsCount = createSelector(getAllTickets, (tickets: Ticket[]) => tickets.length);
export const getCurrentPage = createSelector(selectTicketsState, (state: TicketsState) => state.currentPage);
export const getCurrentTicket = createSelector(
  selectTicketsState,
  getCurrentPage,
  (state: TicketsState, currentPage) => state.entities[state.ids[currentPage - 1]]
);
export const getTicketEntities = createSelector(selectTicketsState, (state: TicketsState) => selectEntities(state));
export const getSelectedTicketId = createSelector(selectTicketsState, (state: TicketsState) => state.selectedId);
export const getSelectedTicket = createSelector(getTicketEntities, getSelectedTicketId, (entities, selectedId) =>
  selectedId ? entities[selectedId] : undefined
);
