import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TicketsActions from './tickets.actions';
import * as TicketsSelectors from './tickets.selectors';

@Injectable()
export class TicketsFacade {
  private readonly store = inject(Store);

  loaded$ = this.store.pipe(select(TicketsSelectors.isLoaded));
  allTickets$ = this.store.pipe(select(TicketsSelectors.getAllTickets));
  allTicketsCount$ = this.store.pipe(select(TicketsSelectors.getAllTicketsCount));
  getCurrentPage$ = this.store.pipe(select(TicketsSelectors.getCurrentPage));
  getCurrentTicket$ = this.store.pipe(select(TicketsSelectors.getCurrentTicket));
  selectedTicket$ = this.store.pipe(select(TicketsSelectors.getSelectedTicket));

  init(pageIndex: number) {
    this.store.dispatch(TicketsActions.initTickets({ pageIndex }));
  }

  changePage(page: number) {
    this.store.dispatch(TicketsActions.changePage({ page }));
  }
}
