import { inject } from '@angular/core';

import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { TicketsFacade } from '../state/tickets/tickets.facade';

export const ticketResolver: ResolveFn<number> = (route: ActivatedRouteSnapshot) => {
  const ticketFacade = inject(TicketsFacade);
  const pageParam = route.queryParamMap.get('page');
  let page = 1;
  if (pageParam) {
    const parsedPage = parseInt(pageParam);
    if (!Number.isNaN(parsedPage)) {
      page = parsedPage;
    }
  }

  ticketFacade.init(page);

  return page;
};
