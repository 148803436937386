import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as TicketsActions from './tickets.actions';
import { Ticket } from './tickets.models';

export const TICKETS_FEATURE_KEY = 'tickets';

export interface TicketsState extends EntityState<Ticket> {
  currentPage: number;
  pageCount: number;
  selectedId?: string | number; // which Tickets record has been selected
  loaded: boolean; // has the Tickets list been loaded
  error?: string | null; // last known error (if any)
}

export interface TicketsPartialState {
  readonly [TICKETS_FEATURE_KEY]: TicketsState;
}

export const ticketsAdapter: EntityAdapter<Ticket> = createEntityAdapter<Ticket>();

export const initialTicketsState: TicketsState = ticketsAdapter.getInitialState({
  // set initial required properties
  currentPage: 0,
  pageCount: 1,
  loaded: false,
  error: null,
});

const reducer = createReducer(
  initialTicketsState,
  on(TicketsActions.initTickets, (state, { pageIndex }) => ({
    ...state,
    currentPage: pageIndex,
  })),
  on(TicketsActions.loadTicketsSuccess, (state, { tickets }) =>
    ticketsAdapter.setAll(tickets, { ...state, loaded: true })
  ),
  on(TicketsActions.loadTicketsFailure, (state, { error }) => ({ ...state, error })),
  on(TicketsActions.changePage, (state, { page }) => ({ ...state, currentPage: page }))
);

export function ticketsReducer(state: TicketsState | undefined, action: Action) {
  return reducer(state, action);
}
